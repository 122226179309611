// main page container for the vault site. header, footer.
import { html, css, LitElement, TemplateResult } from "lit";
import { property, customElement } from "lit/decorators.js";
import vaultLogo from "../../images/vault-logo-3.png";

export enum TopNavUrlPatterns {
  Dashboard = "dashboard",
  Collections = "collections",
  Deposit = "deposit",
  Reports = "reports",
  Administration = "administration",
}

@customElement("vault-page-container")
export class VaultPageContainer extends LitElement {
  @property({ type: Boolean }) isAuthenticated: boolean = false;
  @property({ type: Boolean }) hasReadPermissions: boolean = false;
  @property({ type: String }) vaultVersion: string = "";
  @property({ type: String }) vaultGitCommitHash: string = "";
  @property({ type: String }) orgName: string | undefined = "";
  @property({ type: String }) username: string | undefined = "";

  protected readonly vaultLogo: TemplateResult = html`<img
    src=${vaultLogo}
    alt="Vault logo"
    class="vault-logo"
  />`;

  protected highlightIfCurrentPage(urlPattern: TopNavUrlPatterns): string {
    const topLevelPathname = window.location.pathname.split("/")[1];
    return topLevelPathname === (urlPattern as string) ? "current-page" : "";
  }

  render() {
    return html`
      <div class="page-container">
        <nav>
          <div class="main-header-links">
            <ul>
              <li class="vault-logo-container">
                ${this.hasReadPermissions
                  ? html`
                      <a href="/${TopNavUrlPatterns.Dashboard}">
                        ${this.vaultLogo}
                      </a>
                    `
                  : html`<span>${this.vaultLogo}</span>`}
              </li>
              ${this.hasReadPermissions
                ? html` <li
                    class="header-link ${this.highlightIfCurrentPage(
                      TopNavUrlPatterns.Dashboard,
                    )}"
                  >
                    <a href="/${TopNavUrlPatterns.Dashboard}"> Dashboard </a>
                  </li>`
                : null}
              <li
                class="header-link ${this.highlightIfCurrentPage(
                  TopNavUrlPatterns.Collections,
                )}"
              >
                <a href="/${TopNavUrlPatterns.Collections}"> Collections </a>
              </li>
              ${this.hasReadPermissions
                ? html`
                    <li
                      class="header-link ${this.highlightIfCurrentPage(
                        TopNavUrlPatterns.Deposit,
                      )}"
                    >
                      <a href="/${TopNavUrlPatterns.Deposit}"> Deposit </a>
                    </li>
                    <li
                      class="header-link ${this.highlightIfCurrentPage(
                        TopNavUrlPatterns.Reports,
                      )}"
                    >
                      <a href="/${TopNavUrlPatterns.Reports}"> Reports </a>
                    </li>
                    <li
                      class="header-link ${this.highlightIfCurrentPage(
                        TopNavUrlPatterns.Administration,
                      )}"
                    >
                      <a href="/${TopNavUrlPatterns.Administration}">
                        Administration
                      </a>
                    </li>
                  `
                : null}
            </ul>
          </div>
          <div class="top-right-nav">
            <ul>
              ${this.isAuthenticated && this.orgName && this.hasReadPermissions
                ? html`
                    <li>
                      <a href="/${TopNavUrlPatterns.Dashboard}">
                        ${this.orgName}
                      </a>
                    </li>
                  `
                : null}
              ${this.isAuthenticated && this.orgName && !this.hasReadPermissions
                ? html`<li><div>${this.orgName}</div></li>`
                : null}
              <li>
                <a
                  target="_blank"
                  href="https://vault-webservices.zendesk.com/hc/en-us"
                  >Help Center</a
                >
              </li>
              ${this.isAuthenticated
                ? null
                : html`<li><a href="/accounts/login">Log In</a></li>`}
              ${this.isAuthenticated && this.username
                ? html`
                    <li class="nav-hover-menu">
                      Welcome, ${this.username}
                      <span class="menu-arrow">▾</span>
                      <menu>
                        <li>
                          <a href="/accounts/password_change"
                            >Change Password</a
                          >
                        </li>
                        <li>
                          <a href="/accounts/logout_then_login">Logout</a>
                        </li>
                      </menu>
                    </li>
                  `
                : null}
            </ul>
          </div>
        </nav>
        <div class="page-content">
          <slot></slot>
        </div>
        <footer>
          © Internet Archive Vault — ${this.vaultVersion}
          <code>(${this.vaultGitCommitHash})</code>
        </footer>
      </div>
    `;
  }

  static styles = css`
    div.page-container {
      height: 100vh;
      width: 100vw;
      display: flex;
      flex-direction: column;
    }

    nav {
      width: 100%;
      height: var(--vault-site-nav-height);
      background: var(--vault-top-bar-black);
      display: flex;
      color: white;
    }

    ul,
    menu {
      margin: 0;
      padding: 0;
      display: flex;
      list-style-type: none;
    }

    ul li {
      border-right: 1px solid #3a3a3a;
    }

    a {
      display: block;
      text-decoration: none;
      color: white;
    }

    div.main-header-links ul li.current-page {
      background-color: #d54f46;
    }

    div.main-header-links ul li a,
    div.main-header-links ul li span {
      height: var(--vault-site-nav-height);
      padding: 0 22px;
      display: flex;
      align-items: center;
      font-size: 14.5px;
    }

    div.main-header-links ul li.header-link a:hover {
      background-color: #d54f46;
    }

    li.vault-logo-container {
      background: var(--vault-light-blue);
      margin-left: 16px;
    }

    img.vault-logo {
      height: 58px;
      padding: 4px 0 8px 0;
    }

    footer {
      width: 100%;
      height: var(--vault-site-footer-height);
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--vault-light-grey);
      font-size: 12px;
    }

    div.top-right-nav {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 28px;
    }

    div.top-right-nav ul li {
      font-size: 12px;
    }

    li.nav-hover-menu {
      cursor: pointer;
    }

    div.top-right-nav li a,
    div.top-right-nav li div,
    li.nav-hover-menu {
      padding: 8px 12px;
    }

    li.nav-hover-menu menu {
      display: none;
      background-color: var(--vault-top-bar-black);
      position: absolute;
      top: 28px;
      right: 0;
    }

    li.nav-hover-menu:hover menu {
      display: block;
    }

    span.menu-arrow {
      padding-left: 4px;
    }

    menu li {
      border-bottom: 1px solid #3a3a3a;
    }

    menu li:hover {
      background-color: var(--vault-button-hover-background-color);
    }

    div.page-content {
      width: 100%;
      flex-grow: 1;
      overflow: auto;
    }

    code {
      margin-left: 6px;
      color: var(--vault-button-border);
    }
  `;
}
